<template>
  <div class="SectionPlatformForm-wrapper">
    <section-form v-bind="params" />
  </div>
</template>

<script>
import SectionForm from '../section/SectionForm'

export default {
  name: 'SectionPlatformForm',
  components: {
    SectionForm
  },
  data () {
    return {
      params: {
        type: 'platform'
      }
    }
  }
}
</script>
